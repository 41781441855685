import React, { useEffect } from 'react';
import { Checkbox, FormControl, Input, InputLabel, ListItemText, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem } from 'react-contextmenu';
import { setCompareCompanies, setTimeSlot } from 'reducers/Compare';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 14.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

const TimeSelect = () => {
  const dispatch = useDispatch();

  const getCompanies = () => {

    return [
      {
        name: "Oggi",
        key: ""
      },
      {
        name: "Ultimo Trimestre",
        key: "Ultimo Trimestre"
      },
      {
        name: "Ultimo Semestre",
        key: "Ultimo Semestre"
      },
      {
        name: "Ultimo Anno",
        key: "Ultimo Anno"
      }
    ];
  }

  const companiesData = useSelector(state => state.Compare.timeslot);

  const [companyName, setCompanyName] = React.useState(companiesData);

  const handleChange = (event, nextView) => {
    
    setCompanyName(nextView);
  };

  useEffect(() => {
    // dispatch(setTimeSlot(companyName));
    dispatch(setTimeSlot(companyName === null ? "" : companyName));

  }, [companyName]);


  return (
    <>

      <ToggleButtonGroup orientation="vertical" value={companyName} exclusive onChange={handleChange}>
        {getCompanies().map((company) => {
          return (
            <ToggleButton key={company.key} value={company.key}  aria-label="list">
              {company.name} 
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>

      {/* <FormControl >


     

        <InputLabel style={{ color: 'black' }} id="demo-mutiple-checkbox-label">Andamento</InputLabel>
        <Select style={{ width: "200px", color: 'black' }}
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          
          value={companyName}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected}
          MenuProps={MenuProps}
        >
          {getCompanies().map((company) => {
            return (
              <MenuItem key={company.key} value={company.key} className="d-flex w-50">
                 <Checkbox style={{ paddingTop: '2px' }} checked={companyName.indexOf(company.key) > -1} /> 
                 <ListItemText primary={company.name} className="p-2"/>
              </MenuItem>
            )
          }
          )}
        </Select>
      </FormControl> */} 
    </>
  );
}


export default TimeSelect

