import { useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { setCompareCompanies, setTimeSlot } from 'reducers/Compare';
import { loadData } from 'reducers/Report';
import { setUser } from 'reducers/User';
import { history } from '../../history';

export default function Logout() {

  const dispatch = useDispatch();

  const authenticated = useSelector(state => state.User.user);

  const checkAuth = () => {
    if(authenticated) {
      history.push('/');
    }
  }

  useEffect(checkAuth, [authenticated])
  useEffect(checkAuth, [])
  useEffect(()=> {
    dispatch(setUser(null));
    dispatch(loadData({}));
    dispatch(setCompareCompanies([]));
    dispatch(setTimeSlot(''));

  }, []);

  return null;
}
