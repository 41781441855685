
export const SET_COMPANIES = 'COMPARE/SET_COMPANIES';
export const setCompareCompanies = (companies) => {
  return {
    type: SET_COMPANIES,
    companies
  };
};

export const SET_TIMESLOT = 'COMPARE/SET_TIMESLOT';
export const setTimeSlot = (timeSlot) => {
  return {
    type: SET_TIMESLOT,
    timeSlot
  };
};

export default function reducer(state = {
  companies: [],
  timeslot: '',
}, action) {

  switch (action.type) {
    case SET_COMPANIES:
      return {
        ...state,
        companies: action.companies
      };
    case SET_TIMESLOT:
      return {
        ...state,
        timeslot: action.timeSlot
      };
  }

  return state;
}
