import React, { useEffect } from 'react';
import { Checkbox, FormControl,  FormControlLabel,  FormGroup,  ListItemText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem } from 'react-contextmenu';
import { setCompareCompanies } from 'reducers/Compare';

const  CompareSelect = () => {

  const dispatch = useDispatch();
  const defaultCompany = useSelector(state => state.Report.company);
  const companiesData = useSelector(state => state.Report.companies);

  const getCompanies = () => {
    return Object.entries(companiesData).map((i, k) => {
      return {
        name: i[1].name,
        key: i[0]
      }
    });

  }

  const [companyName, setCompanyName] = React.useState([]);

  const handleChange = (event) => {

    let cc = [...companyName];

    if (event.target.checked) {
      cc.push(event.target.value);
    } else {
      cc.splice(cc.indexOf(event.target.value), 1);
    }
  
    setCompanyName(cc);
  };

  useEffect(()=> {
      dispatch(setCompareCompanies(companyName));
  }, [companyName]);


  return (
    <>
      <FormGroup  >
          {getCompanies().map((company, k) => {
            if(defaultCompany === company.key) return null;
            return (

              <FormControlLabel key={k}
              control={<Checkbox 
                value={company.key} 
                onChange={handleChange} 
                checked={companyName.indexOf(company.key) > -1} 
                style={{padding: '5px'}}
                />}
              label={company.name}
            />
            
            )}
          )}
      </FormGroup>
    </>
  );
}


export default CompareSelect

