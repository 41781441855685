import React, { useState } from 'react';

import { PageTitle } from '../../layout-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, LinearProgress, Card, Menu, Button, Badge, Chip, List, ListItem } from '@material-ui/core';
import avatar2 from '../../assets/images/avatars/avatar2.jpg';
import catimage from '../../assets/images/catimage.png';
import CountUp from 'react-countup';
import Trend from 'react-trend';
import { Radar } from 'react-chartjs-2';
import { CircularProgressbar } from 'react-circular-progressbar';
import GaugeChart from 'react-gauge-chart';
import Chart from 'react-apexcharts';
import illustration3 from '../../assets/images/illustrations/pack1/handshake.svg';
import ChartsApex2 from '../../example-components/ChartsApex/ChartsApex2';
import { imageOverlay } from 'leaflet';







export default function SocialMedia() {




  const options = {
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    }
  };
  const series = [
    {
      data: [30, 40, 25, 50, 49, 21, 70, 51]
    }
  ];



  const data = {
    labels: [
      'Tecnologia  e Comunicazione',
      'Digital Marketing',
      'Social Media',
      'Analytics',
    ],
    datasets: [
      {
        label: 'My First dataset',
        backgroundColor: 'rgba(179,181,198,0.2)',
        borderColor: 'rgba(179,181,198,1)',
        pointBackgroundColor: 'rgba(179,181,198,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        data: [65, 59, 90, 5]
      },
      {
        label: 'My Second dataset',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        pointBackgroundColor: 'rgba(255,99,132,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(255,99,132,1)',
        data: [28, 48, 40, 12]
      }
    ]
  };



  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };



  const [actionsCount, setActionsCount] = useState(0);

  return (
    <>
     


      <>
        <div className="mb-spacing-6 category-view">

          <Grid container spacing={6}>

            <Grid item md={12} lg={12} xl={12}>
              <Card>
                <div className="p-4 category-card">
                  <Grid container spacing={0}>
                    <Grid item md={3} className="text-center category-img-wrapper">
                      <img
                        alt="..."
                        className="img-fluid"
                        style={{ minHeight: '50px', maxHeight: '100px' }}
                        src={`${process.env.PUBLIC_URL}/catimage.png`}
                      />
                    </Grid>
                    <Grid item md={9} className="d-flex align-items-center">
                      <div>
                        <div className="font-size-lg font-weight-bold mb-1">
                          Social Media
                      </div>
                        <p className="opacity-7 font-size-md mb-0">
                        Misuriamo la coerenza tra le scelte di comunicazione e gli strumenti tecnologici adottati. Si valuta la qualità complessiva del progetto
digitale partendo dalle fondamenta.
                      </p>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                {/* <div className="divider" />
              <a
                href="#/"
                onClick={(e) => e.preventDefault()}
                className="px-4 py-3 text-first btn btn-white shadow-none d-flex justify-content-between align-items-center">
                <div>Manage wallets</div>
                <FontAwesomeIcon icon={['fas', 'chevron-right']} />
              </a> */}
              </Card>
            </Grid>

            <Grid item lg={8}>
              <Card className="card-box p-4">
                
                <ChartsApex2 />


              </Card>
            </Grid>

            <Grid item lg={4}>
              <Card className="card-box">
                <div className="card-header-alt px-4 pt-4 pb-0 d-flex align-items-start justify-content-between">
                  <h3>Punteggio categoria</h3>
                </div>
                <div className="d-flex flex-column">
                  <GaugeChart
                    hideText
                    id="chartsGauges2AZZ"
                    nrOfLevels={6}
                    colors={['#1bc943', '#f4772e', '#f83245']}
                    arcWidth={0.3}
                    percent={0.63}
                  />
                  <div className="d-60 border-0 mb-4 mx-auto card-icon-wrapper bg-warning text-white btn-icon">
                      <FontAwesomeIcon
                        icon={['far', 'bell']}
                        className="font-size-xl"
                      />
                      <p className="score-status">MEDIO</p>
                  </div>
                </div>

                <div class="card-header-alt d-flex justify-content-center px-4 pt-4 pb-2">
                  <h6 class="font-weight-bold font-size-xl mb-2 text-black">KPI ANALIZZATE</h6>
                </div>
                <div class="card-header-alt p-4">
                  <Chip label="Tecnologie"></Chip>
                  <Chip label="Comunicazione"></Chip>
                  <Chip label="Web Design"></Chip>
                </div>
              </Card>
            </Grid>

            <Grid item lg={6}>
              <Card className="card-box">
                <div className="card-header-alt px-4 pt-4 pb-0 d-flex align-items-start justify-content-between">
                  <h3>Ottimizzazione Mobile</h3>
                </div>
                <div className="px-1 px-lg-4 pb-3">
                  <List component="div" className="list-group-flush">
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Azienda
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs progress-animated-alt my-2 progress-bar-success"
                          value={65}
                        />
                      </div>
                    </ListItem>
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Media settore
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs progress-animated-alt my-2 progress-bar-success"
                          value={34}
                        />
                      </div>
                    </ListItem>
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Best in class
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs my-2 progress-bar-warning"
                          value={71}
                        />
                      </div>
                    </ListItem>
                  </List>
                </div>
              </Card>
            </Grid>


            <Grid item lg={6}>
              <Card className="card-box">
                <div className="card-header-alt px-4 pt-4 pb-0 d-flex align-items-start justify-content-between">
                  <h3>Prestazioni Mobile</h3>
                </div>
                <div className="px-1 px-lg-4 pb-3">
                  <List component="div" className="list-group-flush">
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Azienda
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs progress-animated-alt my-2 progress-bar-success"
                          value={65}
                        />
                      </div>
                    </ListItem>
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Media settore
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs progress-animated-alt my-2 progress-bar-success"
                          value={34}
                        />
                      </div>
                    </ListItem>
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Best in class
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs my-2 progress-bar-warning"
                          value={71}
                        />
                      </div>
                    </ListItem>
                  </List>
                </div>
              </Card>
            </Grid>

          
            <Grid item lg={6}>
              <Card className="card-box">
                <div className="card-header-alt px-4 pt-4 pb-0 d-flex align-items-start justify-content-between">
                  <h3>Prestazioni Sito Web</h3>
                </div>
                <div className="px-1 px-lg-4 pb-3">
                  <List component="div" className="list-group-flush">
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Azienda
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs progress-animated-alt my-2 progress-bar-success"
                          value={65}
                        />
                      </div>
                    </ListItem>
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Media settore
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs progress-animated-alt my-2 progress-bar-success"
                          value={34}
                        />
                      </div>
                    </ListItem>
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Best in class
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs my-2 progress-bar-warning"
                          value={71}
                        />
                      </div>
                    </ListItem>
                  </List>
                </div>
              </Card>
            </Grid>


            <Grid item lg={6}>
              <Card className="card-box">
                <div className="card-header-alt px-4 pt-4 pb-0 d-flex align-items-start justify-content-between">
                  <h3>Qualità User Experience</h3>
                </div>
                <div className="px-1 px-lg-4 pb-3">
                  <List component="div" className="list-group-flush">
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Azienda
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs progress-animated-alt my-2 progress-bar-success"
                          value={70}
                        />
                      </div>
                    </ListItem>
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Media settore
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs progress-animated-alt my-2 progress-bar-success"
                          value={59}
                        />
                      </div>
                    </ListItem>
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Best in class
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs my-2 progress-bar-warning"
                          value={80}
                        />
                      </div>
                    </ListItem>
                  </List>
                </div>
              </Card>
            </Grid>

            <Grid item lg={6}>
              <Card className="card-box">
                <div className="card-header-alt px-4 pt-4 pb-0 d-flex align-items-start justify-content-between">
                  <h3>Tecnologia e Webdesign</h3>
                </div>
                <div className="px-1 px-lg-4 pb-3">
                  <List component="div" className="list-group-flush">
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Azienda
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs progress-animated-alt my-2 progress-bar-success"
                          value={65}
                        />
                      </div>
                    </ListItem>
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Media settore
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs progress-animated-alt my-2 progress-bar-success"
                          value={34}
                        />
                      </div>
                    </ListItem>
                    <ListItem
                      component="a"
                      button
                      href="#/"
                      disableRipple
                      onClick={(e) => e.preventDefault()}
                      className="d-flex bg-white hover-scale-rounded align-items-center py-3">
                      <div className="flex-fill">
                        <div className="font-weight-bold text-black">
                          Best in class
                        </div>
                        <LinearProgress
                          variant="determinate"
                          className="progress-xs my-2 progress-bar-warning"
                          value={71}
                        />
                      </div>
                    </ListItem>
                  </List>
                </div>
              </Card>
            </Grid>

            <Grid item lg={6}>
            <Card className="d-block px-4 py-5 rounded-sm bg-second hover-scale-lg insights-card">
              <div className="d-block d-xl-flex">
                <div className="rounded-circle flex-shrink-0 btn-icon d-50 bg-white text-second mb-4 mb-xl-0">
                  <FontAwesomeIcon
                    icon={['far', 'bell']}
                    className="font-size-lg"
                  />
                </div>
                <div className="pl-0 pl-xl-4 d-flex justify-content-start align-items-center">
                  <p className="mb-0">Sono disponibili {actionsCount} consigli per migliorare il posizionamento</p>
                </div>
                
              </div>
              
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="px-4 py-3 text-first btn btn-white shadow-none d-flex justify-content-end align-items-center">
                  <div>VAI AGLI INSIGHTS</div>
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                </a>
              
            </Card>
          </Grid>
           

           

            

           


           


           


          </Grid>


        </div>
      </>

    </>
  );
}
