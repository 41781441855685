import React from 'react';

import PageError5051 from '../../example-components/PageError505/PageError5051';
export default function PageError505() {
  return (
    <>
      <PageError5051 />
    </>
  );
}
