import React, { useEffect, useState } from 'react';
import {
  Grid,
  InputAdornment,
  Button,
  TextField
} from '@material-ui/core';
import projectLogo from '../../assets/images/quodigi_io_logo.svg';

import MailOutlineIcon from '@material-ui/icons/MailOutlined';

import LockIcon from '@material-ui/icons/Lock';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'reducers/User';
import { history } from '../../history';
import { authService } from 'service/auth';
import { loadData } from 'reducers/Report';

export default function Login() {

  const dispatch = useDispatch();

  const authenticated = useSelector(state => state.User.user);

  const [error, setError] = useState(false);

  const checkAuth = () => {
    
    if(authenticated) {
      history.push('/');
    }
  }
  useEffect(checkAuth, [authenticated])
  useEffect(checkAuth, [])

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const handleChange = event => {
    event.persist();
    setError(false);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = event => {
    event.preventDefault();
    const { email, password } = formState.values;
    const authData = authService.authenticate(email, password).then(
      authData => {
        
        if(!authData.user || !authData.data ) {
          setError(true);
          return;
        }

        dispatch(setUser(authData.user));
        dispatch(loadData(authData.data));  
      },
      err => {
        setError(true);
        return;
      }
    )

    
  };

  return (
    <>
      <div className="app-wrapper bg-white min-vh-100">
        <div className="app-main min-vh-100">
          <div className="app-content p-0" style={{backgroundColor: "#ffffff"}}>
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5">
                  <Grid item md={10} lg={8} xl={4} className="mx-auto text-center">
                    <div className="text-center mb-5 ">
                      <img
                      style={{width: "75%"}}
                      className="m-3 mb-5"
                        alt="Quodigi Monitor "
                        src={projectLogo}
                      ></img>
                    </div>
                    <form onSubmit={handleSignIn}>
                      <div className="text-center w-75 mx-auto">
                        { error && (
                        <div className="mb-4" className="text-primary">
                          Utente non riconosciuto!
                        </div>
                        )}
                        <div className="mb-4">
                          <TextField
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            name="email"
                            label="Email"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <MailOutlineIcon />
                                  
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <TextField
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            name="password"
                            label="Password"
                            type="password"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon />
                                  
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                        <div className="text-center py-4">
                          <Button 
                          className="btn-primary font-weight-bold w-50 my-2"
                          type="submit"
                          style={{boxShadow: 'none'}}
                          >
                            ACCEDI
                          </Button>
                        </div>
                      </div>
                    </form>

                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
