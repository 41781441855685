export const authService = {
    authenticate,
};

function authenticate(email, password) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    //return fetch(process.env.REACT_APP_API_ENDPOINT + `http://localhost:8000/api/login`, requestOptions)
    return fetch(process.env.REACT_APP_API_URL + `/api/data`, requestOptions)
        .then(response => {

            if (response.status === 401) {
                
              //  window.location.reload(true);
            }
        
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    const error = (data) || response.statusText;
                    return Promise.reject(error);
                }
                
                return data;
            });

            
        });

    console.log(email, password);
    return null;
    
    if (email === 'realemutua@quodigi.com' && password === 'io123') {
        return 'realemutua';
    }
    if (email === 'evolvere@quodigi.com' && password === 'io123') {
        return 'evolvere';
    }
    if (email === 'illycaffe@quodigi.com' && password === 'io123') {
        return 'illycaffe';
    }
    if (email === 'demo@quodigi.com' && password === 'io123') {
        return 'demo';
    }
    if (email === 'davines@quodigi.com' && password === 'io123') {
        return 'davines';
    }
    if (email === 'lafeltrinelli@quodigi.com' && password === 'io123') {
        return 'lafeltrinelli';
    }
    if (email === 'moncler@quodigi.com' && password === 'io123') {
        return 'moncler';
    }
    if (email === 'cotril@quodigi.com' && password === 'io123') {
        return 'cotril';
    }
    return false;
}
