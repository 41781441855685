import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
  Popover,
  Button,
  List,
  ListItem,
} from '@material-ui/core';

import CompareSelect from './CompareSelect';
import TimeSelect from './TimeSelect';
import { DragIndicatorOutlined, TimelineOutlined } from '@material-ui/icons';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const Filters = () => {
  const chartFiltersOptions = {
    chart: {
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      },
      stacked: true
    },
    dataLabels: {
      enabled: true
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '65%'
      }
    },
    stroke: {
      show: false,
      width: 0,
      colors: ['transparent']
    },
    colors: ['#7a7b97', 'rgba(122, 123, 151, 0.15)'],
    fill: {
      opacity: 1
    },
    legend: {
      show: false
    },
    labels: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
      'Last week',
      'Last month',
      'Last year',
      'Last quarter'
    ],
    xaxis: {
      crosshairs: {
        width: 1
      }
    },
    yaxis: {
      min: 0
    }
  };
  const chartFiltersData = [
    {
      name: 'Net Profit',
      data: [2.3, 3.1, 4.0, 3.8, 5.1, 3.6, 4.0, 3.8, 5.1, 3.6, 3.2]
    },
    {
      name: 'Net Loss',
      data: [2.1, 2.1, 3.0, 2.8, 4.0, 3.8, 5.1, 3.6, 4.1, 2.6, 1.2]
    }
  ];

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <div className="d-flex align-items-center popover-header-wrapper">
      
        <span className="pr-3">
          {/* <Badge
            variant="dot"
            overlap="circle"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            badgeContent=" "
            classes={{ badge: 'bg-danger badge-circle-sm' }}> */}
            <Button
              onClick={handleClick2}
              style={{backgroundColor: "white"}}
              className="btn-transition-none  font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44  position-relative">
              <span>
                <DragIndicatorOutlined />
              </span>
            </Button>
          {/* </Badge> */}
          <Popover
            open={open2}
            anchorEl={anchorEl2}
            onClose={handleClose2}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <List
              component="div"
              className="list-group-flush text-left bg-transparent">
              <ListItem>
                <div className="align-box-row">
                  <div className="pl-0">
                    <span className="font-weight-bold d-block">
                      Confronta
                    </span>
                  </div>
                </div>
              </ListItem>
              <ListItem className="d-block py-2">
                <div className="align-box-row mb-1">
                  <div>
                    <CompareSelect></CompareSelect>
                  </div>
                </div>
              </ListItem>
            </List>
          </Popover>
        </span>

        <span className="pr-3">
          {/* <Badge
            variant="dot"
            overlap="circle"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            badgeContent=" "
            classes={{ badge: 'bg-danger badge-circle-sm' }}> */}
            <Button
              onClick={handleClick3}
              style={{backgroundColor: "#fff"}}
              className="btn-transition-none  font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44  position-relative">
              <span>
                <TimelineOutlined />
              </span>
            </Button>
          {/* </Badge> */}
          <Popover
            open={open3}
            anchorEl={anchorEl3}
            onClose={handleClose3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <List
              component="div"
              className=" text-left bg-transparent">
              {/* <ListItem>
                <div className="align-box-row">
                  <div className="pl-0">
                    <span className="font-weight-bold d-block">
                      Andamento
                    </span>
                  </div>
                </div>
              </ListItem> */}
              <ListItem className="d-block py-2">
                <div className=" mb-1">
                  <div>
                    <TimeSelect></TimeSelect>
                  </div>
                </div>
              </ListItem>
             
            </List>
          </Popover>
        </span>
      


       </div>
    </>
  );
};

export default Filters;
