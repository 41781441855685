import illycaffe_report from '../reports/illycaffe.json';
import evolvere_report from '../reports/evolvere.json';
import realemutua_report from '../reports/reale-mutua.json';

import lafeltrinelli_report from '../reports/lafeltrinelli.json';
import moncler_report from '../reports/moncler.json';

import davines_report1 from '../reports/davines/report1.json';
import davines_report_time from '../reports/davines/report_time.json';

import cotril_report1 from '../reports/cotril/report1.json';
import cotril_report_time from '../reports/cotril/report_time.json';

import demo_report1 from '../reports/demo/report1.json';
import demo_report_time from '../reports/demo/report_time.json';


const reports = {
  evolvere: { ...evolvere_report },
  realemutua: { ...realemutua_report },
  illycaffe: { ...illycaffe_report },
  moncler: { ...moncler_report },
  lafeltrinelli: { ...lafeltrinelli_report },
  davines: { ...davines_report1, ...davines_report_time },
  cotril: { ...cotril_report1, ...cotril_report_time },
  demo: { ...demo_report1, ...demo_report_time },
};

const def = {
  industry: "",
  type: "",
  companyName: "",
  companyWebsite: "",
  companyLogo: "",
  company: "",
  pages: {},
  companies: {},
  multicards: {},
};

export const SET_DATA = 'REPORT/SET_DATA';
export const setData = (data) => {
  return {
    type: SET_DATA,
    data
  };
};

export const LOAD_DATA = 'REPORT/LOAD_DATA';
export const loadData = (data) => {

  //const data = reports[name] ;

  return {
    type: LOAD_DATA,
    data
  };
};

export default function reducer(state = def, action) {
  switch (action.type) {
    case SET_DATA:

      return {

        industry: action.data.industry,
        type: action.data.type,
        companyName: action.data.companyName,
        companyWebsite: action.data.companyWebsite,
        companyLogo: action.data.companyLogo,
        company: action.data.company,
        pages: action.data.pages,
        companies: action.data.companies,
        multicards: action.data.multicards,
      };
    case LOAD_DATA:

      if (!action.data) return state;
      return action.data;
      break;

  }
  return state;
}
