
import React from 'react';
import DashboardIcon from '@material-ui/icons/AdjustOutlined';
import TechnologyIcon from '@material-ui/icons/PermDataSettingOutlined';
import CommunicationIcon from '@material-ui/icons/FormatShapesOutlined';
import DigitalMarkegingIcon from '@material-ui/icons/ScatterPlotOutlined';
import SocialMediaIcon from '@material-ui/icons/PublicOutlined';
import AnalyticsIcon from '@material-ui/icons/AssessmentOutlined';
import InsightIcon from '@material-ui/icons/ListAltOutlined';
import { ErrorOutline } from '@material-ui/icons';


export default function Icon(props) {

  const { value } = props;

  const color = '#E52F42';



  if (value === 'overview') return (<DashboardIcon style={{ color: color }} />);
  if (value === 'technology') return (<TechnologyIcon style={{ color: color }} />);
  if (value === 'communication') return (<CommunicationIcon style={{ color: color }} />);
  if (value === 'digital-marketing') return (<DigitalMarkegingIcon style={{ color: color }} />);
  if (value === 'social-media') return (<SocialMediaIcon style={{ color: color }} />);
  if (value === 'analytics') return (<AnalyticsIcon style={{ color: color }} />);
  if (value === 'insights') return (<InsightIcon style={{ color: color }} />);

  return (<ErrorOutline style={{ color: color }} />);

}
