import { createStore, combineReducers, compose } from 'redux';
import reducers from '../reducers';

export default function configureStore() {

  const loadState = () => {
    
    try {

      const serializedState = localStorage.getItem('state_user');
      if (serializedState === null) {
        return undefined;
      }


      return JSON.parse(serializedState);
      

      // return {
      //   User: JSON.parse(serializedState)
      // };


    } catch (err) {
      return undefined;
    }
  }; 

  const saveState = (state) => {
    try {

      // const serializedState = JSON.stringify( state.User);
      // localStorage.setItem('state_user', serializedState);

      const serializedState = JSON.stringify( state);
      localStorage.setItem('state_user', serializedState);

    } catch {
      // ignore write errors
    }
  };

  
  const persistedState = loadState();

  const store =  createStore(
    combineReducers({...reducers}),
    persistedState,
    
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    
    
  );

  store.subscribe(() => {
    saveState(store.getState());

  });


  return store;
}
