import ThemeOptions from './ThemeOptions';
import Report from './Report';
import Compare from './Compare';
import User from './User';

export default {
  ThemeOptions,
  Report,
  Compare,
  User
};
