import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import CompareSelect from 'components/CompareSelect';
import TimeSelect from 'components/TimeSelect';
import Filters from 'components/Filters';
import { Menu } from '@material-ui/icons';
import { Button } from '@material-ui/core';


const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent
        })}>
        <div className="app-header--pane">
          <Button
              onClick={toggleSidebarMobile}
              style={{backgroundColor: "white"}}
              className={clsx(
                'btn-transition-none  font-size-lg p-0  shadow-none border-0 text-center d-44  position-relative  toggle-mobile-sidebar-btn',
                { 'is-active': sidebarToggleMobile }
              )}
              >
              <span>
                <Menu/>
              </span>
            </Button>
          {/* <button
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button> */}
          <div className="d-flex align-items-center  pl-3">

            <Filters></Filters>
           

          </div>


          {/* <HeaderSearch /> */}
          {/* <HeaderMenu /> */}
        </div>
        <div className="app-header--pane">
          {/* <HeaderDots /> */}
          <HeaderUserbox />
          {/* <HeaderDrawer /> */}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
